<template>
	<div class="cursor" v-bind:style="{ top: mY + scrollOffset + 'px', left: mX + 'px' }" ref="cursor">
		<div class="cursor-inner anim-cursor" ref="cursorInn">
			<!-- project cursor -->
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500" ref="circcursor">
				<defs>
					<path d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250" id="textcircle"></path>
				</defs>
				<text dy="0" textLength="1220">
					<textPath xlink:href="#textcircle">View Project - View Project -</textPath>
				</text>
			</svg>
			<div class="simpleCursor" ref="smpcursor"></div>
			<!-- simple cursor -->
		</div>
		<div class="landingCursor anim-cursor" ref="landingCursor">
			<!-- cursor for Landingpage -->
			<!--<img src="../assets/landingImage.png" alt="" style="width: 100%; height: 100%;">-->
			<div class="landingCursorInner anim-smp-opacity" ref="landingCursorInner">
				<p>[ ENTER PORTFOLIO ]</p>
			</div>
		</div>
		<div class="customCursor anim-cursor" ref="customCursor">
			<!-- cursor for custom text -->
			<div class="cc-inner">
				<p ref="ccInnerText"></p>
			</div>
		</div>
	</div>
	<div class="cc-cursor-mobile" ref="cccMobile">
		<span id="cccclose" @click="hideCustomCursorMobile">close</span>
		<p ref="cccMobileText"></p>
	</div>
</template>

<script>
export default {
	name: "Cursor",
	data() {
		return {
			mX: 0,
			mY: 0,
			scrollOffset: 0,
			intervalState: true,
			cursorWidth: 130,
			bWidth: 130, //big width of cursor
			sWidth: 70, //small width of cursor
		};
	},
	created() {
		this.$store.subscribe((mutation, state) => {
			switch (mutation.type) {
				case "scrollPos":
					this.scrollOffset = mutation.payload;
					break;
				case "overCard":
					if (mutation.payload == true) {
						this.bigCursor();
					} else {
						this.smallCursor();
					}
					break;
				case "overLanding":
					if (mutation.payload == true) {
						this.landingCursor();
					} else {
						this.landingCursorClose();
					}
				case "textCursor":
					if (mutation.payload == true && this.$route.path !== "/") {
						this.showCustomCursor();
					} else {
						this.hideCustomCursor();
					}
					break;
				default:
					break;
			}
		});
	},
	methods: {
		mMove: function(event) {
			this.$refs.cursorInn.style.opacity = "1";
			this.mX = event.clientX - this.cursorWidth / 2;
			this.mY = event.clientY - this.cursorWidth / 2;
			this.posCountdown();
		},
		smallCursor() {
			this.setDimensions("cursorInn", this.sWidth);

			this.$refs.smpcursor.style.opacity = "1";
			this.$refs.circcursor.style.opacity = "0";
		},
		bigCursor() {
			this.setDimensions("cursorInn", this.bWidth);

			this.$refs.smpcursor.style.opacity = "0";
			this.$refs.circcursor.style.opacity = "1";
		},
		landingCursor() {
			var vp = window.innerWidth / 2 < window.innerHeight / 2 ? window.innerWidth / 2 : window.innerHeight / 2; //take half of shortest distance in viewport
			this.setDimensions("landingCursor", vp);

			this.$refs.landingCursor.style.opacity = "1";
			this.$refs.landingCursorInner.style.opacity = "1";
		},
		landingCursorClose() {
			this.setDimensions("landingCursor", this.sWidth);

			this.$refs.landingCursor.style.opacity = "0";
			this.$refs.landingCursorInner.style.opacity = "0";
		},
		showCustomCursor() {
			if (this.browserWidth() > 800) {
				// normal text cursor
				var vp = window.innerWidth / 2 < window.innerHeight / 2 ? window.innerWidth / 2 : window.innerHeight / 2; //take half of shortest distance in viewport
				this.setDimensions("customCursor", vp);

				this.$refs.customCursor.style.opacity = "1";
				this.$refs.ccInnerText.style.display = "block";
				this.$refs.ccInnerText.innerHTML = this.$store.state.textCursorText;
				setTimeout(() => {
					this.$refs.ccInnerText.style.opacity = "1";
				}, 300);
			} else {
				// this mobile text cursor
				var vp = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight; //take half of shortest distance in viewport
				this.setDimensions("cccMobile", vp - 50);

				this.$refs.cccMobile.style.display = "flex";
				this.$refs.cccMobile.style.opacity = "1";
				this.$refs.cccMobileText.innerHTML = this.$store.state.textCursorText;
			}
		},
		hideCustomCursor() {
			this.setDimensions("customCursor", this.sWidth);

			this.$refs.customCursor.style.opacity = "0";
			this.$refs.ccInnerText.style.opacity = "0";
			this.$refs.ccInnerText.style.display = "none";

			this.smallCursor();
		},
		hideCustomCursorMobile() {
			this.$refs.cccMobile.style.display = "none";
			this.$refs.cccMobile.style.opacity = "0";
		},
		posCountdown() {
			let self = this;
			let countdowntimer = 4; //100ms
			let counter = 0;

			if (this.intervalState) {
				this.intervalState = false;
				var interV = setInterval(function() {
					counter++;
					if (counter == countdowntimer) {
						//cursor is not moved .
						clearInterval(interV);
						self.intervalState = true;
						if (self.$store.state.overCard == false) {
							self.$refs.cursorInn.style.opacity = "0";
						}
					}
				}, 100);
			}
		},
		browserWidth() {
			return Math.max(
				document.body.scrollWidth,
				document.documentElement.scrollWidth,
				document.body.offsetWidth,
				document.documentElement.offsetWidth,
				document.documentElement.clientWidth
			);
		},
		setDimensions(ref, cursorSize) {
			// with half viewport distance, with cursor width big or small
			let target = this.$refs[ref].style;
			target.width = cursorSize + "px";
			target.height = cursorSize + "px";
		},
	},
	mounted() {
		document.addEventListener("mousemove", this.mMove);
	},
	watch: {
		$route(to, from) {
			//make cursor small when routes are changed
			this.smallCursor();
			this.landingCursorClose();
			this.$store.commit("overCard", false);
			this.$store.commit("overLanding", false);
		},
	},
};
</script>

<style scoped>
svg {
	width: 100%;
	position: absolute;
	opacity: 0;
}
text {
	font-size: 40px;
	text-transform: uppercase;
	letter-spacing: 21px;
	fill: #b1b0ae;
	font-family: "RobotoMedium";

	animation-name: cursor-color;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@keyframes cursor-color {
	0% {
		fill: #b1b0ae;
	}
	20% {
		fill: #fcfffc;
	}
	40% {
		fill: #e0dedc;
	}
	60% {
		fill: #cec5e0;
	}
	80% {
		fill: #8a8594;
	}
	100% {
		fill: #b1b0ae;
	}
}
.cursor {
	position: fixed;
	left: 0;
	top: 0;
	width: 130px;
	height: 130px;

	transition-duration: 400ms;
	transition-timing-function: ease-out;

	pointer-events: none;
	z-index: 8;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cursor-inner {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 100%;

	opacity: 0;

	animation-name: cursor-turn;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@keyframes cursor-turn {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.simpleCursor {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 1px solid var(--unfocusColor);
	border-radius: 100%;
	opacity: 1;
}
.landingCursor {
	position: absolute;
	width: 400px;
	height: 400px;
	border-radius: 100%;
	opacity: 0;
	background-color: var(--darkColor);
}
.landingCursorInner {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}
.landingCursorInner p {
	color: var(--lightColor);
	font-size: 0.8rem;
}

@media only screen and (max-width: 800px) {
	.cursor {
		display: none;
	}
}

.customCursor {
	position: absolute;
	width: 400px;
	height: 400px;
	border-radius: 100%;
	opacity: 0;
}
.cc-inner {
	width: calc(100% - 50px);
	height: calc(100% - 50px);
	padding: 25px;
	background-color: var(--darkColor);
	border-radius: 50%;
	font-size: 1em;
	display: flex;
	justify-content: center;
	cursor: none;
}
.cc-inner p {
	color: var(--lightColor);
	align-self: center;
	opacity: 0;

	-webkit-transition: opacity 0.1s;
	-moz-transition: opacity 0.1s;
	-o-transition: opacity 0.1s;
	transition: opacity 0.1s;
}
.cc-inner p:before {
	content: "What did i learn?";
	color: var(--highlightColor);
	display: block;
	padding-bottom: 5px;
}

/* custom text cursor mobile */
#cccclose {
	position: absolute;
	top: 0;
	color: var(--lightColor);
	padding: 10px 0 0 0;
	border-bottom: 1px solid var(--lightColor);
}
.cc-cursor-mobile {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	display: none;
	opacity: 0;
	transform: translate(-50%, -50%);
	z-index: 4;

	padding: 25px;
	background-color: var(--darkColor);
	border-radius: 50%;
	font-size: 1em;
	/*display: flex;*/
	justify-content: center;
}
.cc-cursor-mobile p {
	color: var(--lightColor);
	align-self: center;
	opacity: 1;

	-webkit-transition: opacity 0.1s;
	-moz-transition: opacity 0.1s;
	-o-transition: opacity 0.1s;
	transition: opacity 0.1s;
}
.cc-cursor-mobile p:before {
	content: "What did i learn?";
	color: var(--highlightColor);
	display: block;
	padding-bottom: 5px;
}
</style>
