<template>
	<div class="footer" ref="footer">
		<FooterInnerSimple @scrollTop="$emit('scrollTop', true)"></FooterInnerSimple>
	</div>
</template>

<script>
import FooterInnerSimple from "./FooterInnerSimple.vue";
export default {
	name: "Footer",
	emits: ["scrollTop"],
	components: {
		FooterInnerSimple,
	},
	data() {
		return {
			footer: true,
		};
	},
	methods: {
		animateFooter(event) {
			let scrolled = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
			let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			let footerOffset = this.$refs.footer.offsetTop;
			let footerInView = footerOffset - windowHeight / 2;

			let documentHeight = document.body.scrollHeight;
			let currentScroll = window.scrollY + window.innerHeight;

			let modifier = 200;
			if (currentScroll + modifier > documentHeight) {
				this.$refs.fcf.style.opacity = "1";
			} else {
				this.$refs.fcf.style.opacity = "0";
			}
		},
	},
};
</script>

<style scoped>
.footerColorFollower {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: #1e1e1ef5;
	top: 0;
	z-index: 5;
	opacity: 0;
	pointer-events: none;
	display: none;
}
.footer {
	width: 100%;
}
</style>
