/**
 * name = displayed project name
 * sDescription = short description that is shown when project is not opened
 * lDescription = long description htat is shown when project is opened
 * date = displayed date of project
 * type = displayed type
 * path = path of first image
 * bgColor = color of shadow behind the first image and the radial color dot on the expandet project
 * percentHeight = calculated Height of image in expanded form = (height / width) * 100
 * subpaths = array of sub images shown on the expandet project
 */

const projects = [
	{
		name: "Behind the board",
		sDescription: '"Behind the board" depicts a students drift into another world during class as it makes its way into the classroom.',
		lDescription: `All classmates fade out, and the blackboard image turns into a mystical world full of light, shadow and reflections.<br>
			The world beyond the blackboard slowly but surely penetrates the classroom.<br>
			The goal of the project was to create almost all the textures and 3D models on my own, to apply a large part of the previously learned methods for creating 
			textures and models and to bring them together.`,
		date: "2021",
		type: "rendering",
		path: require("@/assets/media/ProjectsCover/behindTheBoard/Klassenzimmer.jpg"),
		bgColor: "#C79D5E",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/behindTheBoard/clay.jpg")],
	},
	{
		name: "Unsullied broken",
		sDescription: 'Unsullied broken" shows the decay of a family destroyed by the currently ubiquitous coronavirus.',
		lDescription: `"Unsullied broken," or "unbefleckt zerstört" in German, features an apocalyptic-tinged before-and-after shot of a hospital room,
		which is supposed to tell the story of a destroyed family`,
		date: "2021",
		type: "rendering",
		path: require("@/assets/media/ProjectsCover/UnsulliedBroken.jpg"),
		bgColor: "#6D837C",
		percentHeight: 100,
		subpaths: [require("@/assets/media/ProjectsCover/UnsulliedBrokenClay.jpg")],
	} /*
	{
		name: "Playground closing",
		sDescription: "Playground closing ist die erste Animation einer Reihe, in der das Schließen aller Indoorspielplätze gezeigt wird.",
		lDescription:
			"In der ersten Animation wird das Schließen aller Indoorspielplätze während der Pandemie gezeigt, was es nicht mehr möglich machte, local am Device im Indoorspielplatz zu spielen. \
			Aufgrund dieses Zustandes wurde das auf dem Device spielbare Spiel in ein Handyspiel verwandelt, und es benötigte eine Reihe an Animationen, um diesen Schritt zu erklären.",
		date: "2021",
		type: "animation",
		path: require("@/assets/media/ProjectsCover/Closing.jpg"),
		bgColor: "#818E41",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/Closing.mp4"), require("@/assets/media/ProjectsCover/ClosingClay.jpg")],
	},
	{
		name: "SisyFox turns fat",
		sDescription:
			"SisyFox turns fat ist die zweite Animation einer Reihe, in der der Moment gezeigt wird, in dem die Spielfigur SisyFox eine alles verändernde Idee hat.",
		lDescription:
			"Gezeigt wird die vergehende Zeit, in der SisyFox aufgrund der Pandemie nicht mehr in Indoorspielplätzen spielen kann und final der Moment, in dem SisyFox die Idee hat, das ursprüngliche Spiel, \
			das nur auf dem echten Device gespielt werden kann, in ein Handyspiel zu verwandeln. ",
		date: "2021",
		type: "animation",
		path: require("@/assets/media/ProjectsCover/Turnsfat.jpg"),
		bgColor: "#8DAED2",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/Turnsfat.mp4"), require("@/assets/media/ProjectsCover/TurnsfatClay.jpg")],
	},*/,
	{
		name: "SisyFox animations",
		sDescription: "A series of animations explaining the creation of a mobile game.",
		lDescription: `"Playground Closing", "SisyFox turns fat" and "Transformation", are three videos from a series of animations for the developer studio <a href="https://sisyfox.com/" target="_blank">SisyFox</a>. 
			They explain how the mobile game Downhill Legend came into being.<br>You accompany the character SisyFox on a journey through the world plagued by Corona, which finally ends at the creation of 
			the mobile game.<br>During this project I had the chance to work extensively with animation.`,
		date: "2021",
		type: "animation",
		path: require("@/assets/media/ProjectsCover/Closing.jpg"),
		bgColor: "##798D4C",
		percentHeight: 56.3,
		subpaths: [
			require("@/assets/media/ProjectsCover/Closing.mp4"),
			require("@/assets/media/ProjectsCover/ClosingClay.jpg"),
			require("@/assets/media/ProjectsCover/Turnsfat.mp4"),
			require("@/assets/media/ProjectsCover/TurnsfatClay.jpg"),
			require("@/assets/media/ProjectsCover/Transformation.mp4"),
		],
	} /*
	{
		name: "Backgrounds",
		sDescription: "Canyon und Origin Mountains sind zwei Wandgrafkingen für Umgebungen, in denen das Device von SisyFox aufgebaut wird.",
		lDescription:
			"Sowohl in der Grafik Canyon als auch in Origin Mountain ist die Ingame-Welt des von SisyFox produzierten Spiels zu sehen. \
			Die Grafik Origin Mountain weist zusätzlich die Besonderheit auf, dass ein Banner zwischen zwei Bäumen hängt, \
			an dessen Stelle ein Fernseher angebracht werden kann. Auf diese Weise verschmilzt die Grafik mit der Umgebung.<br>\
			Beide Grafiken sind in einem schmalen und breiten Format vorhanden, das an Wänden angebracht werden kann.",
		date: "2020",
		type: "rendering",
		path: require("@/assets/media/ProjectsCover/Originmountain.jpg"),
		bgColor: "#98AE71",
		percentHeight: 76.6,
		subpaths: [require("@/assets/media/ProjectsCover/Canyon.jpg")],
	},*/ /*
	{
		name: "Transformation",
		sDescription: "Transformation ist der letzte Teil einer Reihe, in der die Verwandlung des Devices in ein Handyspiel gezeigt wird.",
		lDescription:
			"In der dritten und letzten Animation wird die durch SisyFox hervorgerufene magische Verwandlung des Devices in ein Handyspiel gezeigt. Mit der Animation ist die Reise, die SisyFox durchläuft, \
			um das Spiel in ein Handyspiel zu verwandeln. vollends abgeschlossen.",
		date: "2021",
		type: "animation",
		path: require("@/assets/media/ProjectsCover/Transformation.jpg"),
		bgColor: "#7891A1",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/Transformation.mp4")],
	} */ /*
	{
		name: "Assets",
		sDescription: "Assets für das Handyspiel Downhill Legend des Entwicklerstudio SisyFox",
		lDescription:
			"Folgend wird eine Auswahl von Assets gezeigt, die im Handyspiel Downhill Legend wiederzufinden sind. Dazu zählen Rundbögen, \
			durch die man zum Punktegewinn fahren muss,  Rüstungen, die das Leben der Spieler*in erhöhen und Luftruder, die eine höhere Agilität in der Luft bieten.<br>\
			Meine Rolle in diesem Projekt war das reine Modellieren und Rendern. Die Ideen für die Assets und ihre spätere Funktionalität im Spiel entstammen dem SisyFox Team.",
		date: "2021",
		type: "rendering",
		path: require("@/assets/media/ProjectsCover/Ruestung-together.jpg"),
		bgColor: "#CECED2",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/Luftruder.jpg"), require("@/assets/media/ProjectsCover/Rundboegen.jpg")],
	} /*
	{
		name: "Iphone 11 showcase",
		sDescription: "Eine Case Study für fotorealistisches Rendern.",
		lDescription:
			"In dieser Case Study für fotorealistisches Rendern liegt das Hauptaugenmerk auf den richtigen Proportionen und Texturen. Das 3D Modell wird unter anderem in der Animation “Transformation” wiederverwendet.",
		date: "2021",
		type: "rendering",
		path: require("@/assets/media/ProjectsCover/Iphone11.jpg"),
		bgColor: "#667A96",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/Iphone11side.jpg"), require("@/assets/media/ProjectsCover/Iphone11top.jpg")],
	},*/,
	{
		name: "Downhill-Legend",
		sDescription: "Productpage for the mobile game Downhill Legend developed by SisyFox",
		lDescription: `<a href="https://downhill-legend.com/" target="_blank">Downhill-Legend.com</a> is the product page for the mobile game Downhill Legend developed by the developer studio <a href="https://sisyfox.com/" target="_blank">SisyFox</a>. 
		The website allows switching between the languages German and English and links to the Play- and Appstore. 
		The website presents a series of images and videos that give an insight into the game. Also, you can subscribe to a newsletter, through which you will receive news about the game. 
		There is also a press area where journalists can get more information as well as pictures and videos.`,
		date: "2021",
		type: "website",
		path: require("@/assets/media/ProjectsCover/downhillLegend/cover.jpg"),
		bgColor: "#ACD4E4",
		percentHeight: 60.2,
		subpaths: [require("@/assets/media/ProjectsCover/downhillLegend/sub1.jpg"), require("@/assets/media/ProjectsCover/downhillLegend/sub2.jpg")],
	},
	{
		name: "Youria",
		sDescription: "Youria is a digital shelf where you can put all your favorite media like podcasts, movies, books and much more.",
		lDescription: `<a href="https://www.youria.app/" target="_blank">Youria</a> comes from the idea of creating a place that unites all lists of favorite media such as books, podcasts, movies and much more.<br>
		The titles are stored via the app and can be ranked, provided with additional information and easily shared via QR codes or direct links. When the QR code is scanned, 
		either the installed app opens directly or, if the app is not available, a website displays all the information of the shared category, collection or individual title. 
		During the course of the project, I had the chance to learn extensively about app programming. I was also able to talk to many people who tested the app and gave me tips. 
		For me, Youria represents an ideal all-rounder of all possible subject areas such as design and programming that interest me.`,
		date: "Current Project",
		type: "app",
		path: require("@/assets/media/ProjectsCover/youria-cover.jpg"),
		bgColor: "#DCCFBA",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/youria.jpg")],
	},
	{
		name: "Sustainability Cards",
		sDescription: "A digital community to facilitate a more sustainable life",
		lDescription: `Sustainability Cards is the idea for a card-based app designed to help people live a sustainable life. Users can create and find cards with different sustainability tips or hacks.
		Cards can be restaurant recommendations or recycling hacks, for example.
		The goal of Sustainability Cards is to create a community that supports each other with sustainability tips to counteract climate change.`,
		date: "Current Main Project",
		type: "app",
		path: require("@/assets/media/ProjectsCover/Sustainabilitycards.jpg"),
		bgColor: "#aed272",
		percentHeight: 100,
		subpaths: [],
	} /*
	{
		name: "AsskPeaches",
		sDescription: "A incredibly fun game to get closer to each other.",
		lDescription: `<a href="https://www.asskpeaches.com/" target="_blank">AsskPeaches</a> is a sex-positive game that is all about bringing more fun and adventure into your relationships or friendships.<br>\
Based on different games, each with their own decks, it offers a diverse selection for almost any situation.\
Through this project, I had the opportunity to go through the entire workflow of making an app a reality.\
From drawing the graphics, to programming the app and website, to setting up the playstore entry.`,
		date: "Current Project",
		type: "app",
		path: require("@/assets/media/ProjectsCover/asskpeaches/ap_one.jpg"),
		bgColor: "#7a5ba7",
		percentHeight: 56.3,
		subpaths: [require("@/assets/media/ProjectsCover/asskpeaches/ap_two.jpg")],
	},*/,
];

module.exports = projects;
