<template>
	<div
		class="anim-all willChange"
		:class="sub && choosenProjectIndexArray.includes(index) ? 'cardBig' : 'card'"
		v-for="(item, index) in Projects"
		:key="item.name"
		:id="item.name.toLowerCase().replace(/\s+/g, '')"
		@mouseover="mouseOverCard"
		@mouseout="mouseOutCard"
	>
		<div class="img-header anim-all">
			<p id="ih1">{{ index + 1 }}/{{ Projects.length }}</p>
			<section id="ihSection">
				<p id="ih2" class="anim-all">{{ item.name }} <span class="anim-trans-opacity">(get insights)</span></p>
				<p id="ih3">{{ item.date }}</p>
				<img
					v-show="sub && choosenProjectIndexArray.includes(index)"
					class="closerIcon"
					@click="closeProject(index)"
					ref="closerimg"
					src="../assets/icons/close.png"
					alt="close"
				/>
				<div v-show="sub && choosenProjectIndexArray.includes(index)" class="anim-opacity color-grad">
					<svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
						<defs>
							<radialGradient r="0.5" cy="0.5" cx="0.5" spreadMethod="pad" :id="'svg_' + index">
								<stop offset="0" stop-opacity="0.5" :stop-color="item.bgColor" />
								<stop offset="1" stop-opacity="0" stop-color="#ffffff" />
							</radialGradient>
						</defs>
						<g>
							<title>Layer 1</title>
							<ellipse ry="80" rx="80" id="svg_7" cy="80" cx="80" :fill="'url(#svg_' + index + ')'" />
						</g>
					</svg>
				</div>
			</section>
		</div>

		<div
			class="anim-smp-opacity-height-padding-margin"
			:class="sub && choosenProjectIndexArray.includes(index) ? 'img-description-big' : 'img-description-small'"
		>
			<div class="isd">
				<p v-html="item.lDescription"></p>
			</div>
			<div class="isA">
				<p>{{ item.type }}</p>
				<p>{{ item.date }}</p>
			</div>
		</div>

		<div
			class="media-wrapper anim-all"
			:id="'media-wrapper' + index"
			:style="[styleNegative, 'backgroundColor:' + item.bgColor]"
			@click="displayProject(item.name, index)"
		>
			<div
				class="img-holder anim-all"
				:ref="'itemholder' + index"
				v-if="item.path.substr(item.path.lastIndexOf('.') + 1) !== 'mp4'"
				:style="[
					style,
					`backgroundImage: url(${item.path}); padding: ${choosenProjectIndexArray.includes(index) ? item.percentHeight : 0}% 0 0 0`,
				]"
			></div>
		</div>

		<div class="img-description">
			<div class="isd">
				<p>{{ item.sDescription }}</p>
			</div>
			<div class="ist">
				<p>{{ item.type }}</p>
			</div>
		</div>
		<div class="sub-images" v-show="sub && choosenProjectIndexArray.includes(index)">
			<SubMedia :index="index" :fireDisplay="choosenProjectIndexArray.includes(index) ? fireDisplay : false"></SubMedia>
		</div>
	</div>
</template>

<script>
import SubMedia from "./SubMedia";
import Projects from "./Projects";

export default {
	name: "ProjectCard",
	components: {
		SubMedia,
		Projects,
	},
	emits: ["focusProject"],
	props: ["posX", "posY", "getProjectFunc"],
	data() {
		return {
			Projects,
			changed: false,
			changedOut: false,
			colorSpeed: 30,
			sub: false, //open project
			choosenProjectIndex: -1,
			choosenProjectIndexArray: [], // stores index of all opened projects
			firstLoad: true,
			fireDisplay: false,
			fullMediaHeight: 0,
		};
	},
	watch: {
		getProjectFunc: function(newVal) {
			if (newVal == true && this.firstLoad == true) {
				this.getProject();
			}
		},
	},
	computed: {
		style() {
			if (this.$store.state.disableCardAnimation == false && this.posX !== 0) {
				let browserWidth = this.$store.state.browserWidth;
				let browserHeight = this.$store.state.browserHeight;
				return {
					transform:
						"translate(" +
						-(this.posX - browserWidth / 2) / this.colorSpeed +
						"px," +
						-(this.posY - browserHeight / 2) / this.colorSpeed +
						"px",
				};
			} else {
				return { transform: "translate(0, 0)", backgroundColor: "var(--lightColor)" };
			}
		},
		styleNegative() {
			if (this.$store.state.disableCardAnimation == false && this.posX !== 0) {
				let browserWidth = this.$store.state.browserWidth;
				let browserHeight = this.$store.state.browserHeight;
				return {
					transform:
						"translate(" +
						(this.posX - browserWidth / 2) / this.colorSpeed +
						"px," +
						(this.posY - browserHeight / 2) / this.colorSpeed +
						"px)",
				};
			} else {
				return { transform: "translate(0, 0)", backgroundColor: "var(--lightColor)" };
			}
		},
	},
	methods: {
		displayProject(name, index) {
			console.log(name, index);

			this.choosenProjectIndex = index;
			this.choosenProjectIndexArray.push(index);

			this.sub = true;
			this.fireDisplay = true;

			this.$router.push("/work/" + name.toLowerCase().replace(/\s+/g, ""));
			this.focusProject(name);
		},
		getProject() {
			if (this.$route.params && Object.keys(this.$route.params).length !== 0) {
				this.fireDisplay = false; //when project is clicked without closing previous
				let para = this.$route.params.catchAll.split("/").pop();
				for (let a = 0; a < this.Projects.length; a++) {
					if (para === this.Projects[a].name.toLowerCase().replace(/\s+/g, "")) {
						this.displayProject(this.Projects[a].name.toLowerCase().replace(/\s+/g, ""), a);
					}
				}
				this.firstLoad = false;
			}
		},
		closeProject(index) {
			//this.choosenProjectIndex = -1;
			this.choosenProjectIndex = 1;

			const arrPos = this.choosenProjectIndexArray.indexOf(index);
			if (arrPos > -1) {
				this.choosenProjectIndexArray.splice(arrPos, 1);
				//this.choosenProjectIndexArray.sort(function(a, b){return a-b}); //sort array
			}
			this.$router.push("/work");
		},
		focusProject(name) {
			this.$emit("focusProject", name);
		},
		mouseOverCard() {
			this.$store.commit("overCard", true);
		},
		mouseOutCard() {
			this.$store.commit("overCard", false);
		},
	},
};
</script>

<style scoped>
.willChange {
	will-change: width;
}
.card {
	position: relative;
	top: 0px;
	margin-bottom: 100px;
	color: white;
	opacity: 1;
}

#ih1 {
	font-size: 0.7em;
}
#insights {
	align-self: flex-end;
}
#ih2 {
	position: relative;
	font-size: 1.5em;
	font-family: "RobotoMedium";
	z-index: 2;
}
#ih2 span {
	font-size: 0.8rem;
	font-family: "Roboto";
	cursor: pointer;
}
#ih3 {
	font-size: 0.7em;
	align-self: flex-end;
	margin: 0 0 3px 0;
}
.closerIcon {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.img-header {
	position: relative;
	z-index: 1;
}
.img-header section {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	align-items: center;
}

.media-wrapper {
	position: relative;
	background-color: var(--verylightColor);
	z-index: 3;
}

.img-holder {
	cursor: pointer;
	height: 35vw;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	will-change: padding;
}

.img-holder img {
	width: 100%;
	display: none;
}
.video-holder video {
	width: 100%;
	height: 13vw;
}
.img-description {
	display: flex;
	margin-top: 10px;
}
.isd {
	flex-grow: 2;
	font-size: 0.8rem;
}
.ist {
	flex-grow: 1;
	margin-left: 10px;
	font-size: 0.8em;
	text-transform: uppercase;
}
.ist p {
	float: right;
}

#minfo {
	margin: 10px 0 0 0;
	text-decoration: underline #000;
}

/* subimages */

.sub-images {
	width: 100%;
}

/* big mobile */
.cardBig {
	width: 90%;
	margin: 0 0 20% 5%;
	padding: 60% 0 0 0;
}
.img-description-small {
	position: relative;
	padding: 0;
	height: 0;
	opacity: 0;
	width: 78vw;
	pointer-events: none;
	z-index: 2;
}
.img-description-big {
	position: relative;
	width: 78vw;
	margin: 0 0 0 5%;
	padding: 10px 0 0 0;
	/*height: 200px;*/
	opacity: 1;
	z-index: 2;
}
.cardBig .img-header {
	width: 90%;
	margin: 0 0 0 5%;
}
.cardBig .img-header #ihSection #ih2 {
	font-size: 2.5rem;
}
.cardBig .img-header #ihSection #ih2 span {
	opacity: 0;
}
.cardBig .img-header #ihSection #ih3 {
	opacity: 0;
}
.cardBig .media-wrapper .img-holder {
	height: 0;
}
.cardBig .img-description {
	display: none;
}
.isA {
	display: flex;
	justify-content: space-between;
	padding: 40px 0 40px 0;
	font-size: 0.9rem;
}
.color-grad {
	position: absolute;
	margin: 0 0 0 -80px;
	z-index: 1;
	opacity: 1;
}
.color-grad-hidden {
	opacity: 0;
}

@media only screen and (min-width: 800px) {
	.card {
		width: 45%;
	}
	.card:nth-child(even):hover {
		margin-left: 52.5%;
	}
	.card:nth-child(odd):hover {
		margin-left: 2.5%;
	}
	/* big */
	.cardBig {
		width: 70%;
		margin: 0 0 20% 15%;
		padding: 20% 0 0 0;
	}
	.img-description-small {
		padding: 0 0 0 0;
		height: 0;
		margin: 0;
		opacity: 0;
		width: 53vw;
		pointer-events: none;
		z-index: 2;
	}
	.img-description-big {
		width: 53vw;
		margin: 0 0 0 10%;
		padding: 10px 0 80px 0;
		/*height: 200px;*/
		opacity: 1;
		z-index: 2;
	}
	.cardBig .img-header {
		width: 80%;
		margin: 0 0 0 10%;
	}
	.cardBig .img-header #ihSection #ih2 {
		font-size: 3rem;
	}
	.cardBig .img-header #ihSection #ih2 span {
		opacity: 0;
	}
	.cardBig .img-header #ihSection #ih3 {
		opacity: 0;
	}
	.cardBig .media-wrapper .img-holder {
		height: 0;
	}
	.cardBig .img-description {
		display: none;
	}
	.isA {
		display: flex;
		justify-content: space-between;
		padding: 40px 0 40px 0;
		font-size: 0.9rem;
	}
	.color-grad {
		position: absolute;
		margin: 0 0 0 -80px;
		z-index: 1;
		opacity: 1;
	}
	.color-grad-hidden {
		opacity: 0;
	}

	.img-holder {
		height: 13vw;
	}
	.card:nth-child(even) {
		margin-left: 55%;
	}
}
</style>
