<template>
	<div class="work-main-wrapper" ref="workMainWrapper">
		<div class="hd anim-trans-opacity">
			<Header></Header>
		</div>
		<div class="project-wrapper anim-trans-opacity" :class="{ 'anim-translate': animateWork }" ref="pw">
			<ProjectCard :posX="posX" :posY="posY" @focusProject="focusProject" :getProjectFunc="getProjectVar"></ProjectCard>
		</div>
		<About></About>
		<Footer @scrollTop="scrollTop"></Footer>
	</div>
</template>

<script>
import Header from "../components/Header.vue";
import ProjectCard from "../components/ProjectCard.vue";
import Footer from "../components/Footer.vue";
import Projects from "../components/Projects";
import About from "../components/About";

export default {
	name: "Work",
	components: {
		Header,
		ProjectCard,
		Projects,
		About,
		Footer,
	},
	data() {
		return {
			posX: 0,
			posY: 0,
			animateWork: false,
			Projects,
			visibilityOffset: 100,
			lastGetterCounter: 0,
			lastElem: "",
			nextElem: "",
			getProjectVar: false,
		};
	},
	methods: {
		animateWorkImages: function(event) {
			let mouseX = event.clientX;
			let mouseY = event.clientY;
			this.posX = event.clientX;
			this.posY = event.clientY;
		},
		focusProject(value) {
			let searchElem = "#" + value.toLowerCase().replace(/\s+/g, "");
			document.querySelector(searchElem).scrollIntoView({
				behavior: "smooth",
			});
		},
		scrollTop() {
			window.scrollTo(0, 0);
		},
	},
	mounted() {
		if (this.$route.hash) {
			const hashelem = document.querySelector(this.$route.hash);
			this.scroll.scrollTo(hashelem.id);
		}

		if (window.matchMedia("(min-width: 800px)").matches) {
			document.addEventListener("mousemove", this.animateWorkImages);
			this.animateWorkImages({ clientX: 0, clientY: 0 }); //set translate of work to 0
			this.animateWork = true; // add animation to work wrapper
		}
		this.getProjectVar = true;
	},
	unmounted() {
		document.removeEventListener("mousemove", this.animateWorkImages);
	},
};
</script>

<style scoped>
.project-wrapper {
	width: calc(100% - 5%);
	margin: 100px 0 0 2.5%;
}
</style>
