import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import SmoothScrollbar from 'vue-smooth-scrollbar'
//import SmoothVuebar from 'smooth-vuebar'

//createApp(App).use(store).use(router).use(SmoothScrollbar, {damping: 0.05, renderByPixels: true}).mount('#app')
//createApp(App).use(store).use(router).use(SmoothVuebar).mount('#app')
createApp(App).use(store).use(router).mount('#app')
