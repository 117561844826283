<template>
	<div class="home-main-wrapper" ref="homeWrapper">
		<div class="home-wrapper">
			<div class="left">
				<p>3d</p>
			</div>
			<div class="home-link-activater" ref="hlActivater">
				<router-link to="/work" tag="div" class="home-link" @mouseover="landingActivater" @mouseout="landingDeactivater">
					<div class="home-video">
						<img src="../assets/Portfolio.gif" />
					</div>
				</router-link>
			</div>
			<div class="right">
				<p>Artist</p>
			</div>
		</div>
		<div class="preload" v-if="prefetchWork" v-show="false">
			<Work></Work>
		</div>
	</div>
</template>

<script>
import Header from "../components/Header.vue";
import Work from "../views/Work.vue";

export default {
	name: "Home",
	data() {
		return {
			prefetchWork: false,
		};
	},
	components: {
		Header,
		Work,
	},
	methods: {
		landingActivater() {
			this.$store.commit("overLanding", true);
		},
		landingDeactivater() {
			this.$store.commit("overLanding", false);
		},
	},
	mounted() {
		document.onreadystatechange = () => {
			// prefetch work for better user experience
			if (document.readyState == "complete") {
				this.prefetchWork = true;
			}
		};
	},
};
</script>

<style scoped>
.preload {
	display: block;
}
.home-wrapper {
	position: absolute;
	width: 100%;
	height: calc(100vh - 20px);
	/*background-color: var(--darkColor);*/
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.left,
.right {
	font-size: 5rem;
	text-transform: uppercase;
	flex-basis: 33.3%;
}
@media only screen and (max-width: 800px) {
	.left,
	.right {
		font-size: 1rem;
	}
}
.left p {
	float: right;
	margin-left: 2.5vw;
	font-family: "RobotoBold";
}
.right p {
	margin-right: 2.5vw;
	font-family: "RobotoBold";
}
.right p:after {
	position: absolute;
	display: block;
	content: "[click in the image to resume]";
	font-size: 0.8rem;
	font-family: "RobotoThin";
	padding-right: 2.5vw;
}

.home-link {
	width: 100%;
	height: 100%;
	/*
  background: rgb(177,176,174);
  background: radial-gradient(circle, rgba(177,176,174,1) 0%, rgba(30,30,30,1) 70%);*/
	border-radius: 100%;
}
.home-link-activater {
	flex-basis: 33.3%;
	max-width: 600px;
}
.home-video img {
	width: 100%;
	cursor: none;
	clip-path: circle(50% at 50% 50%);
	/*animation: turn 100s linear infinite;*/
}
@keyframes turn {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
