import { createStore } from "vuex";

export default createStore({
	state: {
		overCard: false,
		offset: 0,
		offsetvid: 0,
		overLanding: false,
		textCursor: false,
		textCursorText: "",
		disableCardAnimation: false,
		browserWidth: 0,
		browserHeight: 0,
		scrollPos: 0,
	},
	mutations: {
		overCard(state, n) {
			state.overCard = n;
		},
		offset(state, n) {
			state.offset = n;
		},
		offsetvid(state, n) {
			state.offsetvid = n;
		},
		overLanding(state, n) {
			state.overLanding = n;
		},
		textCursor(state, n) {
			state.textCursor = n;
		},
		textCursorText(state, n) {
			state.textCursorText = n;
		},
		disableCardAnimation(state, n) {
			state.disableCardAnimation = n;
		},
		browserWidth(state, n) {
			state.browserWidth = n;
		},
		browserHeight(state, n) {
			state.browserHeight = n;
		},
		scrollPos(state, n) {
			state.scrollPos = n;
		},
	},
	actions: {},
	modules: {},
});
