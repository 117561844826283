<template>
	<div class="about-wrapper">
		<div class="about-wrapper-inner">
			<div class="about-header">
				<p>About me<span>.</span></p>
				<!--<img id="meOne" src="../assets/metwo.jpg" alt="Me, by Anneke Haase">-->
			</div>
			<div class="about-info">
				<section>
					<span>How I got here</span>
					<div>
						<p>
							I'm Silas, a 21-year-old student who woke up 5 years ago with the desire to program his own game, totally screwed up the
							idea, but fell hopelessly in love with 3D design and programming during the process. Since then, my day consists of
							bringing ideas to life.
						</p>
					</div>
				</section>
				<section>
					<span>Work experience (I'm new to this)</span>
					<div>
						<WorkExperience></WorkExperience>
					</div>
				</section>
				<!--
				<section>
					<span>Me at a glance</span>
					<div>
						<p>Something goes here<br />And more here<br />And even more here<br />And last but not last<br />Here</p>
					</div>
				</section>
                -->
			</div>
		</div>
		<Connect></Connect>
	</div>
</template>

<script>
import WorkExperience from "./WorkExperience.vue";
import Connect from "./Connect.vue";

export default {
	name: "About",
	components: {
		WorkExperience,
		Connect,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style scoped>
.about-wrapper {
	width: 95%;
	margin: 25vh 0 100px 2.5%;
}
.about-wrapper-inner {
	display: flex;
	flex-direction: column;
}
.about-header,
.about-info {
	flex-basis: 50%;
}
.about-header p {
	font-size: 3rem;
	font-family: "RobotoMedium";
}
.about-header span {
	font-size: 3rem;
	color: var(--highlightColor);
}
#meOne {
	width: 50%;
	margin: 20px 0 20px 0;
}

.about-info section {
	margin: 0 0 80px 0;
}
.about-info span {
	font-size: 0.8rem;
	font-family: "Roboto";
}
.about-info div {
	margin: 10px 0 0 0;
}
.info div p {
	font-size: 1rem;
}

@media only screen and (min-width: 800px) {
	.about-wrapper-inner {
		flex-direction: row;
	}
	.connect-wrapper-inner {
		flex-direction: row;
	}
}
</style>
