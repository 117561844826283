<template>
	<div class="we-wrapper">
		<div class="wrapper-one" @mouseover="showCustomCursor" @mouseout="hideCustomCursor" @click="showCustomCursor">
			<div class="we-inner">
				<p id="wione"><a href="https://www.sisyfox.com" target="_blank">SisyFox </a><span>| 3D Artist</span></p>
				<span>-</span>
				<p id="witwo">2020 - present</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "WorkExperience",
	props: ["colorType"],
	data() {
		return {};
	},
	methods: {
		showCustomCursor() {
			this.$store.commit(
				"textCursorText",
				"I learned what it means to be a team player, how to work with deadlines, and how to implement a style that is different from my own, more realistic art style."
			);
			this.$store.commit("textCursor", true);
		},
		hideCustomCursor() {
			this.$store.commit("textCursor", false);
		},
	},
};
</script>

<style scoped>
a {
	text-decoration: none;
}
.we-inner {
	display: flex;
	justify-content: space-between;
	cursor: none;
}

#wione {
	font-family: "RobotoMedium";
}
#wione:before {
	content: " ";
	display: block;
	position: absolute;
	margin: -5px 0 0 -5px;
	width: 5px;
	height: 5px;
	background-color: var(--darkColor);
	border-radius: 2.5px;

	animation-name: clickhint;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}
@keyframes clickhint {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

#wione span {
	font-family: "Roboto";
	font-size: 0.9em;
}
#witwo {
	font-size: 0.9em;
}
</style>
