<template>
	<div class="sub-container anim-height-slow" ref="subImagesContainer">
		<div class="sub-container-app anim-opacity" ref="sca">
			<div class="subinner anim-opacity" v-for="item in Projects[index].subpaths" :key="item.subpaths" ref="subInner">
				<img class="subinnerImage" :src="item" alt="sub image" v-if="item.substr(item.lastIndexOf('.') + 1) !== 'mp4'" ref="subImages" />

				<video controls v-if="item.substr(item.lastIndexOf('.') + 1) == 'mp4'">
					<source :src="item + '#t=0.1'" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	</div>
</template>

<script>
import Projects from "./Projects";

export default {
	name: "SubMedia",
	props: ["index", "fireDisplay"],
	components: {
		Projects,
	},
	data() {
		return {
			Projects,
		};
	},
	watch: {
		fireDisplay: function(newVal) {
			if (newVal == true) {
				this.startAnim();
			} else if (newVal == false) {
				this.resetAnim();
			}
		},
	},
	methods: {
		startAnim() {
			this.$nextTick(function() {
				//let dom load after display none

				let heightAmount = this.Projects[this.index].subpaths.length; //amount of submedia
				this.$refs.subImagesContainer.style.height = heightAmount * 100 + "vw";
				setTimeout(() => {
					//this.$refs.subInner.style.display = "block";
					this.$refs.sca.style.display = "block";

					this.$nextTick(function() {
						//this.$refs.subInner.style.opacity = "1";
						this.$refs.sca.style.opacity = "1";
					});

					this.$refs.subImagesContainer.style.height = "auto"; //
				}, 1000); //match animation duration
			});
		},
		resetAnim() {
			this.$refs.subImagesContainer.style.height = "0";
			this.$refs.sca.style.opacity = "0";
		},
	},
};
</script>

<style scoped>
.sub-container {
	height: 0;
}
/*
.subinnerImage::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: "red";
}
*/
.subinner {
	position: relative;
	width: 100%;
	margin: 20px 0 0 0;
	z-index: 2;
}
.sub-container-app {
	display: none;
	opacity: 0;
}
.subinner img {
	width: 100%;
}
.subinner video {
	width: 100%;
}

@media only screen and (min-width: 800px) {
	.subinner {
		margin: 40px 0 0 0;
	}
}
</style>
