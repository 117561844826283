<template>
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component :is="Component"></component>
		</transition>
	</router-view>
	<Cursor></Cursor>
</template>

<script>
import Cursor from "./components/Cursor.vue";

export default {
	components: {
		Cursor,
	},
	created() {
		if (window.matchMedia("(max-width: 800px)").matches) {
			this.$store.commit("disableCardAnimation", true);
		}
		let browserWidth = Math.max(
			document.body.scrollWidth,
			document.documentElement.scrollWidth,
			document.body.offsetWidth,
			document.documentElement.offsetWidth,
			document.documentElement.clientWidth
		);
		let browserHeight = Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.offsetHeight,
			document.documentElement.clientHeight
		);
		this.$store.commit("browserWidth", browserWidth);
		this.$store.commit("browserHeight", browserHeight);
	},
};
</script>

<style>
@font-face {
	font-family: "RobotoThin";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(./fonts/Roboto-Light.ttf);
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(./fonts/Roboto-Regular.ttf);
}
@font-face {
	font-family: "RobotoMedium";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(./fonts/Roboto-Medium.ttf);
}
@font-face {
	font-family: "RobotoBold";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(./fonts/Roboto-Bold.ttf);
}
* {
	padding: 0;
	margin: 0;
}
html {
	scroll-behavior: smooth;
}
:root {
	--darkColor: #1e1e1e;
	--lightColor: #f1efed;
	--unfocusColor: #b1b0ae;
	--verylightColor: #dad8d5;
	--lightdarkColor: #383737;
	--highlightColor: #cec5e0;
}
body {
	background-color: #f1efed;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	overflow-x: hidden;
}
body::-webkit-scrollbar {
	display: none;
}
::selection {
	background: #000; /* WebKit/Blink Browsers */
	color: #f1efed;
}
::-moz-selection {
	background: #000; /* Gecko Browsers */
	color: #f1efed;
}
#app {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
p,
a,
span {
	color: var(--darkColor);
	text-rendering: optimizeSpeed;
	-webkit-text-rendering: optimizeSpeed;
}
.app-wrapper {
	height: 100vh;
}

/* animater classes */

.anim-opacity {
	-webkit-transition: opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-smp-opacity {
	-webkit-transition: opacity 0.1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 0.1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 0.1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 0.1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-smp-opacity-height-margin {
	-webkit-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s,
		cubic-bezier(0.23, 0.8, 0.36, 0.94) margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-smp-opacity-height-padding {
	-webkit-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s,
		cubic-bezier(0.23, 0.8, 0.36, 0.94) padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-smp-opacity-height-padding-margin {
	-webkit-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s,
		cubic-bezier(0.23, 0.8, 0.36, 0.94) padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 0.4s cubic-bezier(0.23, 0.8, 0.36, 0.94), height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94),
		padding 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), margin 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-trans-opacity {
	-webkit-transition: opacity 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-opacity-top {
	-webkit-transition: opacity 0.5s, top 0.5s;
	-moz-transition: opacity 0.5s, top 0.5s;
	-o-transition: opacity 0.5s, top 0.5s;
	transition: opacity 0.5s, top 0.5s;
}
.anim-cursor {
	-webkit-transition: opacity 0.7s, width 0.3s, height 0.3s;
	-moz-transition: opacity 0.7s, width 0.3s, height 0.3s;
	-o-transition: opacity 0.7s, width 0.3s, height 0.3s;
	transition: opacity 0.7s, width 0.3s, height 0.3s;
}
.anim-background {
	-webkit-transition: background-color 0.5s;
	-moz-transition: background-color 0.5s;
	-o-transition: background-color 0.5s;
	transition: background-color 0.5s;
}
.anim-color {
	-webkit-transition: color 0.5s;
	-moz-transition: color 0.5s;
	-o-transition: color 0.5s;
	transition: color 0.5s;
}
.anim-width {
	-webkit-transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-height {
	-webkit-transition: height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: height 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-height-slow {
	-webkit-transition: height 3s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: height 13 cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: height 3s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: height 3s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-width-opacity {
	-webkit-transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 2s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 2s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 2s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: width 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 2s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-translate {
	-webkit-transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-translate-opacity {
	-webkit-transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: transform 1s cubic-bezier(0.23, 0.8, 0.36, 0.94), opacity 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.anim-all {
	-webkit-transition: all 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: all 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: all 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: all 1s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}

/* route transitons */

.route-enter-from {
	opacity: 0;
}
.route-enter-active {
	transition: all 0.5s ease-out;
}
.route-leave-to {
	opacity: 0;
}
.route-leave-active {
	transition: all 0.5s ease-in;
}

/* buttons */

.button {
	background-color: var(--darkColor);
	color: var(--lightColor);
	border: none;
	outline: none;
	padding: 5px 20px;
	border-radius: 5px;
	margin: 20px 0 0 0;
	cursor: pointer;
}
</style>
