<template>
	<div class="connect-wrapper-inner">
		<div class="connect-left">
			<div>
				<p id="con-left-first">Get in touch</p>
				<p>+49 (0) 1522 6983293</p>
				<p>heringsilas@gmail.com</p>
			</div>
			<div class="connect-left-connect">
				<p id="con-left-first">Connect</p>
				<a href="https://www.behance.net/heringsila11ab"
					>Behance
					<p>/heringsila11ab</p></a
				>
				<a href="https://www.instagram.com/sjeydo/"
					>Instagram
					<p>/sjeydo</p></a
				>
				<a href="https://dribbble.com/Jeydo"
					>Dribbble
					<p>/Jeydo</p></a
				>
			</div>
		</div>
		<div class="connect-right"></div>
	</div>
</template>

<script>
export default {
	name: "Connect",
	components: {},
	data() {
		return {};
	},

	methods: {},
};
</script>

<style scoped>
.connect-wrapper-inner {
	display: flex;
	flex-direction: column;
	border-top: 1px solid var(--verylightColor);
}
.connect-right {
	flex-basis: 50%;
	margin: 20px 0 0 0;
}
.connect-left {
	flex-basis: 50%;
	display: flex;
	margin: 40px 0 0 0;
}
.connect-left div {
	flex-basis: 50%;
}
#con-left-first {
	margin: 0 0 20px 0;
	font-size: 0.8rem;
}
.connect-left-connect a {
	display: flex;
	align-items: flex-end;
}
.connect-left-connect a p {
	font-size: 0.9rem;
	font-family: "RobotoThin";
	padding: 0 0 0 10px;
}

@media only screen and (min-width: 800px) {
	.connect-wrapper-inner {
		flex-direction: row;
	}
}
</style>
