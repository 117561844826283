<template>
	<div class="footer-inner">
		<div class="end">
			<p>&#64;2023 Silas Hering &bull; All rights reserved</p>
		</div>
		<p @click="scrollTop" id="scrollToTop">scroll to top</p>
	</div>
</template>

<script>
export default {
	name: "FooterInnerSimple",
	emits: ["scrollTop"],
	data() {
		return {
			footer: true,
		};
	},
	methods: {
		scrollTop() {
			this.$emit("scrollTop", true);
		},
	},
};
</script>

<style scoped>
/* new footer */
.footer-inner {
	width: 95%;
	margin: 0 0 0 2.5%;
	padding: 80px 0 2.5% 0;
	display: flex;
	justify-content: space-between;
	font-size: 0.9rem;
}
.end {
	display: flex;
	flex-direction: column;
}
#scrollToTop {
	cursor: pointer;
}
@media only screen and (min-width: 800px) {
	.end {
		flex-direction: row;
	}
}
</style>
