import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/work",
		name: "Work",
		component: () => import("../views/Work.vue"),
	},
	{
		path: "/work/project/:project",
		name: "Project",
		component: () => import("../views/Project.vue"),
	},
	{
		path: "/:catchAll(.*)",
		component: () => import("../views/Work.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior: function(to, from, savedPosition) {
		//return {left: 0, top: 0}
	},
});

export default router;
