<template>
	<div class="header-wrapper" ref="hw">
		<router-link to="/work">WORK</router-link>
		<router-link to="/about" @click="scrollToBottom">ABOUT</router-link>
	</div>
</template>

<script>
export default {
	name: "Header",
	data() {
		return {
			linkColor: "var(--unfocusColor)",
			scrolledLast: 0,
		};
	},
	mounted() {
		window.addEventListener("scroll", this.animateHeader);
	},
	unmounted() {
		window.removeEventListener("scroll", this.animateHeader);
	},
	methods: {
		animateHeader(event) {
			let scrolled = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

			if (scrolled < 50 || scrolled < this.scrolledLast) {
				this.showHeader();
			} else {
				this.hideHeader();
			}
			this.scrolledLast = scrolled;
		},
		showHeader() {
			let hd = this.$refs.hw;
			hd.style.opacity = "1";
			hd.style.top = "0";
		},
		hideHeader() {
			let hd = this.$refs.hw;
			hd.style.opacity = "0";
			hd.style.top = "-30px";
		},
		scrollToBottom() {
			window.scrollTo(0, document.body.scrollHeight);
		},
	},
};
</script>

<style scoped>
.header-wrapper {
	width: calc(100% - 5%);
	padding: 2.5% 0 0 2.5%;
	display: flex;
	justify-content: space-between;
	font-size: 0.9em;
	font-family: "RobotoBold";
	position: fixed;
	z-index: 4;
	top: 0;

	opacity: 1;

	-webkit-transition: opacity 0.3s, top 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-moz-transition: opacity 0.3s, top 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	-o-transition: opacity 0.3s, top 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
	transition: opacity 0.3s, top 0.5s cubic-bezier(0.23, 0.8, 0.36, 0.94);
}
.header-wrapper a {
	text-decoration: none;
	color: var(--darkColor);
}
.router-link-exact-active {
	color: var(--darkColor) !important;
	border-bottom: 1px solid var(--darkColor) !important;
}
</style>
